<script>
import { mapActions } from 'vuex'
import api from '@/services/secureHttps';
import Spinner from "@/components/spinner";

export default {
  components: {
    Spinner
  },

  data() {
    return {
      email: "",
      password: "",
      errorMessages: [],
      isLoading: false
    }
  },

  mounted() {
    document.querySelectorAll("form .auth-pass-inputgroup")
      .forEach(function (item) {
        item.querySelectorAll(".password-addon").forEach(function (subitem) {
          subitem.addEventListener("click", function () {
            var passwordInput = item.querySelector(".password-input");
            if (passwordInput.type === "password") {
              passwordInput.type = "text";
            } else {
              passwordInput.type = "password";
            }
          });
        });
      });
  },

  methods: {
    ...mapActions('context', [
      'login'
    ]),

    async onLogin() {
      this.isLoading = true;
      this.login({ email: this.email, password: this.password })
        .then(() => {

          api.getUserProfile()
            .then((response) => {
              var data = response.data;
              if (typeof data.organisations === 'undefined' || data.organisations.length == 0) {
                this.$router.push({ name: 'organisationSetup' });
              }
            });

          this.$router.push({ name: 'default' });
        }, error => {
          console.log(error);
          let responseData = error.response.data;


          if (responseData.status == 400) {
            this.errorMessages = ['Sorry, we could not sign you in. Either email does not exist or password is incorrect.']
          }
          else {
            this.errorMessages = ['Unkown error. Sorry we could not complete sign in. Our tech team will look at the problem. Please try again later.']
          }
        }).finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<template>
  <div class="auth-page-wrapper pt-5">
    <!-- auth page bg -->
    <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
      <div class="bg-overlay"></div>

      <div class="shape">

        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 1440 120">
          <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
        </svg>
      </div>
      <Particles id="tsparticles" :options="{
        particles: {
          number: {
            value: 90,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: '#ffffff',
          },
          shape: {
            type: 'circle',
            stroke: {
              width: 0,
              color: '#000000',
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: 'img/github.svg',
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.8,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0,
              sync: false,
            },
          },
          size: {
            value: 4,
            random: true,
            anim: {
              enable: false,
              speed: 4,
              size_min: 0.2,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 150,
            color: '#ffffff',
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 2,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: true,
              mode: 'bubble',
            },
            onclick: {
              enable: true,
              mode: 'repulse',
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 4,
              duration: 2,
              opacity: 0.8,
              speed: 3,
            },
            repulse: {
              distance: 200,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
        config_demo: {
          hide_card: false,
          background_color: '#b61924',
          background_image: '',
          background_position: '50% 50%',
          background_repeat: 'no-repeat',
          background_size: 'cover',
        },
      }" />
    </div>

    <!-- auth page content -->
    <div class="auth-page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center mt-sm-5 mb-4 text-white-50">
              <div>
                <router-link to="/" class="d-inline-block auth-logo">
                  <img src="@/assets/images/logo/logo-small.png" alt="" height="155" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- end row -->

        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card mt-4">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to Semantic Click.</p>
                </div>
                <div class="p-2 mt-4">
                  <form @submit.prevent="onLogin">
                    <div class="mb-3">
                      <label for="username" class="form-label">Email address</label>
                      <input type="text" class="form-control" id="email" placeholder="Enter email address"
                        v-model="email" />
                    </div>

                    <div class="mb-3">
                      <div class="float-end">
                        <router-link to="/auth/reset-pwd-basic" class="text-muted">Forgot password?</router-link>
                      </div>
                      <label class="form-label" for="password-input">Password</label>
                      <div class="position-relative auth-pass-inputgroup mb-3">
                        <input type="password" class="form-control pe-5  password-input" placeholder="Enter password"
                          id="password-input" v-model="password" />
                        <button
                          class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button" id="password-addon"><i class="ri-eye-fill align-middle"></i></button>
                      </div>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                      <label class="form-check-label" for="auth-remember-check">Remember me</label>
                    </div>

                    <div class="mt-4">
                      <button class="btn btn-success w-100">
                        Sign In
                      </button>
                      <div class="mt-2">
                        <Spinner :isLoading="isLoading"></Spinner>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->

            <div id="errorMsg" class="card" v-show="errorMessages.length > 0">
              <div class="card-body">
                <div class="card-title">Register Errors</div>
                <ul class="list-group">
                  <li v-for="error in errorMessages" :key="error.id" class="list-group-item list-group-item-danger">
                    {{ error }}</li>
                </ul>
              </div>
            </div>
            <!--end validation error card-->

            <div class="mt-4 text-center">
              <p class="mb-0">
                Don't have an account ?
                <router-link to="/account/register" class="fw-bold text-primary text-decoration-underline">
                  Signup
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0 text-muted">
                &copy; {{ new Date().getFullYear() }} Semantic Click.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
  <!-- end auth-page-wrapper -->
</template>